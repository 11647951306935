<template>
    <div class="">
        <h1>HOME</h1>
    </div>
</template>
<script>
export default {
    name: "Home",
    components: {
    },
    computed : {
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/css/template.scss";
.loader{
    position: absolute;
    background: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}
.content {
    width: 100%;
    .aside-right {
        width: calc(100% - 0px);
        min-height: 100vh;
        // background: linear-gradient(45deg,rgba(255,255,255,.7), rgba(255,255,255,0.7)),url("../../assets/img/pattern.png");
        background: linear-gradient(45deg, rgba(0,0,0,.5),rgba(0,0,0,.5)),url("../../assets/img/login-side-bg.jpg");
        background-position: center;
        background-repeat: repeat-x;
        background-size: cover;
        @include row;
        @include align-center;
        @include justify-center;
        position: relative;
        .header{
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            @include row;
            @include align-center;
            span{
                font-family: $primary-font;
                font-size: 13px;
                font-weight: 600;
                color: #ffffff;
            }
            button{
                font-family: $primary-font;
                font-size: 13px;
                margin-left: 10px;
                color: rgba(255, 255, 255,1);
                padding: 8px;
                border-color: #ffffff;
            }
        }
       .card{
        padding: 20px;
        border:none;
        max-width: 520px;
        width: 100%;
        .card-title{
            font-family: $primary-font;
            font-size: 26px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .fields{
            label{
                font-family: $primary-font;
                font-size: 13px;
                font-weight: 600;
                display: block;
                margin-bottom: 5px;
            }
            input{
                font-family: $primary-font;
                font-size: 13px;
                width: 100%;
                display: block;
            }
            .p-password{
                width: 100%;
                .p-password-input{
                    width: 100%;
                }
            }
            span{
                font-family: $primary-font;
                color: red;
                font-size: 15px;
                font-weight: 600;
            }
        }
        .forgot-pass{
            text-align: right;
            span{
                font-family: $primary-font;
                font-size: 13px;
                font-weight: 600;
                color: #2271e7;
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .login-btn{
            width: 150px;
            margin: 0 auto;
            a{
                text-decoration: none;
                width: 100%;
            }
            button{
                font-family: $primary-font;
                font-size: 14px;
                padding: 10px 20px;
                background: $primary-color;
            }
        }
       }
    }
}

input{
    display: block;
    width: 100%;
}
</style>